import { map, filter } from "lodash"
import { createSlice } from "@reduxjs/toolkit"
import { dispatch } from "../store"
// utils
import { FacilityManager } from "../../@types/facility"
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc
} from "firebase/firestore"
import { findCarrierById } from "./carrier"
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
type FacilityState = {
  isLoading: boolean
  error: boolean
  facilityList: FacilityManager[]
}

const initialState: FacilityState = {
  isLoading: false,
  error: false,
  facilityList: []
}

const slice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // DELETE facility
    deleteFacility(state, action) {
      const deleteFacility = filter(
        state.facilityList,
        (facility) => facility.id !== action.payload
      )
      state.facilityList = deleteFacility
    },
    // GET MANAGE facility
    getFacilityListSuccess(state, action) {
      state.isLoading = false
      state.facilityList = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// Actions
export const { deleteFacility } = slice.actions

// ------------------------Get facility List--------------------------------
export function getFacilityList() {
  return async () => {
    dispatch(slice.actions.startLoading())
    try {
      const facilitys = await getDocs(collection(getFirestore(), "facilitys"))
      let findDocs = facilitys.docs.map((facility) => {
        let data = facility.data()
        data.id = facility.id
        return data
      })
      dispatch(slice.actions.getFacilityListSuccess(findDocs))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
export async function findFacilityById(id: string) {
  const findFacilityDetails = await getDoc(doc(db, 'facilitys', id))
  if (findFacilityDetails.exists()) {
    return findFacilityDetails.data();
  }
};
