// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import Label from "../../components/Label";
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};

const sidebarConfig = (isAdmin: boolean) => [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: "general",
  //   items: [
  //     {
  //       title: "app",
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard,
  //     },
  //     {
  //       title: "e-commerce",
  //       path: PATH_DASHBOARD.general.ecommerce,
  //       icon: ICONS.ecommerce,
  //     },
  //     {
  //       title: "analytics",
  //       path: PATH_DASHBOARD.general.analytics,
  //       icon: ICONS.analytics,
  //     },
  //     {
  //       title: "banking",
  //       path: PATH_DASHBOARD.general.banking,
  //       icon: ICONS.banking,
  //     },
  //     {
  //       title: "booking",
  //       path: PATH_DASHBOARD.general.booking,
  //       icon: ICONS.booking,
  //     },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "Dashboard",
    items: [
      {
        title: "load",
        path: PATH_DASHBOARD.load.root,
        icon: ICONS.user,
      },
      {
        title: "carrier",
        path: PATH_DASHBOARD.carrier.root,
        icon: ICONS.user,
      },
      {
        title: "broker",
        path: PATH_DASHBOARD.broker.root,
        icon: ICONS.user,
      },
      {
        title: "driver",
        path: PATH_DASHBOARD.driver.root,
        icon: ICONS.user,
      },
      {
        title: "truck",
        path: PATH_DASHBOARD.truck.root,
        icon: ICONS.user,
      },
      {
        title: "trailer",
        path: PATH_DASHBOARD.trailer.root,
        icon: ICONS.user,
      },
      {
        title: "facility",
        path: PATH_DASHBOARD.facility.root,
        icon: ICONS.user,
      },
      // {
      //   title: "trip",
      //   path: PATH_DASHBOARD.trip.root,
      //   icon: ICONS.user,
      // },
    ],
  },
  {
    subheader: "account",
    items: [
      // MANAGEMENT : USER
      // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      // { title: 'list', path: PATH_DASHBOARD.user.list },
      // { title: 'create', path: PATH_DASHBOARD.user.newUser },
      // { title: 'edit', path: PATH_DASHBOARD.user.editById },
      {
        title: "settings",
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.user,
      },
      ...(isAdmin
        ? [
            {
              title: "admin",
              path: PATH_DASHBOARD.user.list,
              icon: ICONS.kanban,
            },
          ]
        : []),

      // // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },

      // // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
      //   ]
      // }
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: "app",
  //   items: [
  //     {
  //       title: "mail",
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>,
  //     },
  //     { title: "chat", path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     {
  //       title: "calendar",
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: "kanban",
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
];

export default sidebarConfig;
