import React from "react";

import { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import useLocalStorage from "../hooks/useLocalStorage";
import firebase from "firebase/app";
import {
  getFirestore,
  collection,
  getDoc,
  addDoc,
  query,
  orderBy,
  limit,
  setDoc,
  updateDoc,
  doc,
  serverTimestamp,
  getDocs,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { AuthContextType, AuthState } from "../@types/authentication";
import { initializeApp } from "firebase/app";
import { dispatch } from "redux/store";
import { resetUser } from "redux/slices/user";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const AuthContext = React.createContext<AuthContextType | null>(null);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let userUnsubscribe: Unsubscribe;
    const authUnsubscribe = getAuth().onAuthStateChanged(
      async (firebaseUser) => {
        if (firebaseUser) {
          try {
            userUnsubscribe = onSnapshot(
              doc(getFirestore(), "users", firebaseUser.uid),
              (userSnap: any) => {
                if (userSnap.exists()) {
                  setUser({
                    ...userSnap.data(),
                    email: firebaseUser.email,
                    uid: firebaseUser.uid,
                  });
                }
              }
            );
          } catch (error) {
            console.error(error);
          }
        } else {
          setUser(null);
        }
        setIsInitialized(true);
      }
    );

    return () => {
      authUnsubscribe();
      userUnsubscribe();
    };
  }, []);

  const loginWithGoogle = () => {
    return null;
  };

  const loginWithFaceBook = () => {
    return null;
  };

  const loginWithTwitter = () => {
    return null;
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => { };

  const resetPassword = async (email: string) => {
    try {
      // const actionCodeSettings = {
      //   // After password reset, the user will be give the ability to go back
      //   // to this page.
      //   url: "console.bridge18.com/",
      //   handleCodeInApp: false,
      // };
      await sendPasswordResetEmail(getAuth(), email);
    } catch (error) { }
  };

  const login = async (email: string, password: string) => {
    let loginUser = await signInWithEmailAndPassword(getAuth(), email, password);
    setUser(loginUser)
    return loginUser
  }

  const logout = async () => {
    setUser(null);
    await getAuth().signOut();
    dispatch(resetUser());
  };

  return (
    <AuthContext.Provider
      value={{
        ...initialState,
        isInitialized,
        user,
        login,
        logout,
        register,
        resetPassword,
        updateProfile: () => { },
        method: "firebase",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
