import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utilsimport { getFirestore, collection, getDocs, getDoc, doc, } from "firebase/firestore";
import { TruckManager } from "../../@types/truck";
import { getFirestore, collection, getDocs, getDoc,doc} from "firebase/firestore";
import { findCarrierById } from "./carrier";
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig);
const db = getFirestore(app)
// ----------------------------------------------------------------------

type TruckState = {
    isLoading: boolean;
    error: boolean;
    truckList: TruckManager[];
};

const initialState: TruckState = {
    isLoading: false,
    error: false,
    truckList: [],
};

const slice = createSlice({
    name: "truck",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE TRUCK
        deleteTruck(state, action) {
            const deleteTruck = filter(
                state.truckList,
                (truck) => truck.id !== action.payload
            );
            state.truckList = deleteTruck;
        },
        // GET MANAGE TRUCK
        getTruckListSuccess(state, action) {
            state.isLoading = false;
            state.truckList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTruck } = slice.actions;

// ------------------------Get Truck List--------------------------------
export function getTruckList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const trucks = await getDocs(collection(getFirestore(), "trucks"));
            let findDocs = trucks.docs.map((truck) => {
                let data = truck.data();
                data.id = truck.id;
                return data
            });
            const truckDocs = await Promise.all(
                findDocs.map(
                    (state) => findCarrierById(state.carrierId).then(carrierDetails => ({ ...state, carrierDetails }))
                )
            );
            dispatch(
                slice.actions.getTruckListSuccess(truckDocs)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export async function findTruckbyId(id: string) {
    const findTruckDetails = await getDoc(doc(db, 'trucks', id))
    if (findTruckDetails.exists()) {
        return findTruckDetails.data();
    }
};
 