import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import {
    getFirestore,
    collection,
    getDocs,
    getDoc,
    doc,
    addDoc,
    setDoc,
    deleteDoc,
    query,
    where,
    updateDoc,
    orderBy,
    limit,
} from "firebase/firestore";
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig);
const db = getFirestore(app);
type StopState = {
    isLoading: boolean;
    error: boolean;
    stopList: any[];
};

const initialState: StopState = {
    isLoading: false,
    error: false,
    stopList: [],
};


const slice = createSlice({
    name: "stop",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // ADD LOAD
        addLoadSuccess(state, action) {
            state.isLoading = false;
            state.stopList = [...state.stopList, action.payload];
        },
        // DELETE LOAD
        deleteLoad(state, action) {
            const deleteLoad = filter(
                state.stopList,
                (stop) => stop.id !== action.payload
            );
            state.stopList = deleteLoad;
        },
        // GET MANAGE STOP
        getStopListSuccess(state, action) {
            state.isLoading = false;
            state.stopList = action.payload;
        },
    },
});
// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, addLoadSuccess, deleteLoad, getStopListSuccess } = slice.actions;

// ------------------------Get Stop List--------------------------------
export function getStopList() {
    return async () => {
        try {
            const stops = await getDocs(collection(getFirestore(), "stops"));
            let doc = stops.docs.map((stop) => {
                return stop.data();
            });
            dispatch(getStopListSuccess(doc))
            return doc
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}
export function addStop(data: any) {
    return async () => {
        try {
            await setDoc(doc(db, "stops", data.uid), { ...data, type: "saved" })
        }
        catch (err) {
            console.log("error while adding stop ===>", err)
            dispatch(hasError(err));
        }
    };
}
export function updateStop(data: any) {
    return async () => {
        try {
            await updateDoc(doc(db, "stops", data.uid), { ...data, type: "saved" })
        }
        catch (err) {
            console.log("error while adding stop ===>", err)
            dispatch(hasError(err));
        }
    };
}
export function removeStops(data: any) {
    return async () => {
        try {
            await deleteDoc(doc(db, "stops", data))
        }
        catch (err) {
            console.log("error while deleteing package ===>", err)
            dispatch(hasError(err));
        }
    };
}

export async function getLoadStops(loadId: string) {
    return await getDocs(query(collection(getFirestore(), "stops"), where("loaduid", "==", loadId))).then((response) => {
        let docs = response.docs.map((stop) => {
            let data = stop.data();
            return data
        })
        docs.sort((a: any, b: any) =>
            a.index - b.index
        );
        return docs;
    });
}


export function updateStopsbyId(id: string, data: any) {
    return async () => {
        try {
            await updateDoc(doc(db, 'stops', id), { ...data })
        }
        catch (error) {
            console.log("stops error ===", error)
        }
    }
};
