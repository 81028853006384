import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { DispatchManager } from "../../@types/dispatch";
import { getFirestore, collection, getDocs, getDoc, doc, addDoc, query, where, deleteDoc, updateDoc, setDoc } from "firebase/firestore";
// ----------------------------------------------------------------------
import { updateStop } from "./stop";

const db = getFirestore()
type DispatchState = {
    isLoading: boolean;
    error: boolean;
    dispatchList: DispatchManager[];
};

const initialState: DispatchState = {
    isLoading: false,
    error: false,
    dispatchList: [],
};

const slice = createSlice({
    name: "dispatches",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // ADD NEW DISPATCH
        addDispatchSuccess(state, action) {
            state.isLoading = false;
            state.dispatchList = [...state.dispatchList, action.payload];
        },

        // DELETE LOAD
        deleteDispatch(state, action) {
            const deleteDispatch = filter(
                state.dispatchList,
                (dispatches) => dispatches.uid !== action.payload
            );
            state.dispatchList = deleteDispatch;
        },
        // GET MANAGE LOAD
        getDispatchListSuccess(state, action) {
            state.isLoading = false;
            state.dispatchList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, addDispatchSuccess, deleteDispatch, getDispatchListSuccess, hasError } = slice.actions;

// ------------------------Get Load List--------------------------------
export function getDispatchList() {
    return async () => {
        dispatch(startLoading());
        try {
            const dispatches = await getDocs(collection(getFirestore(), "dispatches"));
            let findDocs = dispatches.docs.map((load) => {
                let data = load.data();
                return data
            });
            dispatch(
                getDispatchListSuccess(findDocs)
            );
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}
export function createDispatch(data: any, event?: string) {
    return async () => {
        try {
            dispatch(startLoading());
            await setDoc(doc(db, "dispatches", data.uid), { ...data })
            data.stops.map((e: any) => {
                dispatch(updateStop({ uid: e, dispatchId: data.uid }))
            })
            dispatch(getDispatchList());
            if (event == "edit") {
                return `Dispatch Edited Successfully`
            } else {
                return `Dispatch Created Successfully`
            }
        } catch (error) {
            console.log("dispatch error ===", error)
            dispatch(hasError(error));
        }
    }
}

export async function findDispatchesByLoadId(loadId: string) {
    return await getDocs(query(collection(getFirestore(), "dispatches"), where("loadId", "==", loadId))).then((response) => {
        return response.docs.map((load) => {
            let data = load.data();
            data.id = load.id;
            return data
        })
    });
}

export function deleteDispatchesById(dispatchId: string) {
    return async () => {
        try {
            await deleteDoc(doc(db, "dispatches", dispatchId))
            await getDocs(query(collection(getFirestore(), "stops"), where("dispatchId", "==", dispatchId))).then((response) => {
                response.docs.map((stop) => {
                    dispatch(updateStop({ uid: stop.id, dispatchId: "" }))
                })
            });
            dispatch(getDispatchList());
            return "Dispatch Deleted Successfully"
        } catch (error) {
            dispatch(hasError(error));
        }
    }
}

export async function getDispatchesbyId(id: string) {
    const findDispatchDetails = await getDoc(doc(db, 'dispatches', id))
    if (findDispatchDetails.exists()) {
        let data = findDispatchDetails.data()
        data.id = id
        return data;
    }
}

export function updateDispatchebyId(id: string, data: any) {
    return async () => {
        try {
            await getDocs(query(collection(getFirestore(), "stops"), where("dispatchId", "==", id))).then((response) => {
                response.docs.map((stop) => {
                    ;
                    dispatch(updateStop({ uid: stop.id, dispatchId: "" }))
                })
            });
            await updateDoc(doc(db, 'dispatches', id), { ...data })
            data?.stops?.map((e: any) => {
                dispatch(updateStop({ uid: e, dispatchId: data.uid }))
            })
            dispatch(getDispatchList())
        }
        catch (error) {
            console.log("dispatch error ===", error)
            dispatch(hasError(error));
        }
    }
};

export function removeDispatchesByLoadId(loadId: string) {
    return async () => {
        try {
            await getDocs(query(collection(getFirestore(), "dispatches"), where("loadId", "==", loadId))).then((response) => {
                return response.docs.map(async (load) => {
                    await deleteDoc(doc(db, "dispatches", load.id))
                    console.log("success dispatch")
                })

            });
        } catch (error) {
            console.log("error ===>", error)
            dispatch(hasError(error));
        }
    };
}