import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utilsimport { getFirestore, collection, getDocs, getDoc, doc, } from "firebase/firestore";
import { TrailerManager } from "../../@types/trailer";
import { getFirestore, collection, getDocs, getDoc, doc } from "firebase/firestore";
import { findCarrierById } from "./carrier";
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig);
const db = getFirestore(app)
// ----------------------------------------------------------------------

type TrailerState = {
    isLoading: boolean;
    error: boolean;
    trailerList: TrailerManager[];
};

const initialState: TrailerState = {
    isLoading: false,
    error: false,
    trailerList: [],
};

const slice = createSlice({
    name: "trailer",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE TRUCK
        deleteTrailer(state, action) {
            const deleteTrailer = filter(
                state.trailerList,
                (trailer) => trailer.id !== action.payload
            );
            state.trailerList = deleteTrailer;
        },
        // GET MANAGE TRUCK
        getTrailerListSuccess(state, action) {
            state.isLoading = false;
            state.trailerList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTrailer } = slice.actions;

// ------------------------Get Trailer List--------------------------------
export function getTrailerList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const trailers = await getDocs(collection(getFirestore(), "trailers"));
            let findDocs = trailers.docs.map((trailer) => {
                let data = trailer.data();
                data.id = trailer.id;
                return data
            });
            const trailerDocs = await Promise.all(
                findDocs.map(
                    (state) => findCarrierById(state.carrierId).then(carrierDetails => ({ ...state, carrierDetails }))
                )
            );
            dispatch(
                slice.actions.getTrailerListSuccess(trailerDocs)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export async function findTrailerById(id: string) {
    const findTrailerDetails = await getDoc(doc(db, 'trailers', id))
    if (findTrailerDetails.exists()) {
        return findTrailerDetails.data();
    }
};
