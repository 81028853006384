import { filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { LoadManager } from "../../@types/load";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  updateDoc,
  orderBy,
  limit,
} from "firebase/firestore";
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
import { addStop, removeStops } from "./stop";
const app = initializeApp(fbConfig);
const db = getFirestore(app);
type LoadState = {
  isLoading: boolean;
  error: boolean;
  loadList: LoadManager[];
};

const initialState: LoadState = {
  isLoading: false,
  error: false,
  loadList: [],
};


const slice = createSlice({
  name: "load",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ADD LOAD
    addLoadSuccess(state, action) {
      state.isLoading = false;
      state.loadList = [...state.loadList, action.payload];
    },
    // DELETE LOAD
    deleteLoad(state, action) {
      const deleteLoad = filter(
        state.loadList,
        (load) => load.id !== action.payload
      );
      state.loadList = deleteLoad;
    },
    // GET MANAGE LOAD
    getLoadListSuccess(state, action) {
      state.isLoading = false;
      state.loadList = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, addLoadSuccess, deleteLoad, getLoadListSuccess } = slice.actions;

// ------------------------Get Load List--------------------------------
export function getLoadList() {
  return async () => {
    dispatch(startLoading());
    try {
      const loads = await getDocs(collection(getFirestore(), "loads"));
      let findDocs = loads.docs.map((load) => {
        let data = load.data();
        data.id = load.id;
        return data;
      });
      findDocs.sort((a: any, b: any) =>
        b.loadNumber - a.loadNumber
      );
      dispatch(getLoadListSuccess(findDocs));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function getCount() {
  const db = getFirestore();
  const loadsCollectionRef = collection(db, "loads");
  const q = query(loadsCollectionRef, orderBy("createdAt", "desc"), limit(1));
  const querySnapshot = await getDocs(q);
  const loadsData: any = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
  let count = 0;
  if (loadsData.length > 0) {
    count = loadsData[0].loadNumber
  }
  return count + 1;
}
export function addLoad(values: any, packages: any, stops: any, rateSheet: any) {
  return async () => {
    try {
      dispatch(startLoading());
      const loadId = await getCount();
      const data = {
        loadNumber: loadId,
        customerId: values.customerId,
        contactRef: values.contactRef,
        customerLoad: values.customerLoad,
        carrierId: values.carrierId,
        status: values.status,
        rateDetails: {
          rate: values.rateDetails.rate,
          type: values.rateDetails.type,
        },
        salesPersonId: values.salesPersonId,
        freightDetails: {
          freightType: values.freightDetails.freightType,
          commodity: values.freightDetails.commodity,
          temperature: values.freightDetails.temperature,
          temperatureType: values.freightDetails.temperatureType,
          cycle: values.freightDetails.cycle,
          pulp: values.freightDetails.pulp,
        },
        requirements: {
          trailerType: values.requirements.trailerType,
          trailerSize: values.requirements.trailerSize,
          team: values.requirements.team,
        },
        createdAt: Date.now(),
        updatedAt: null,
        files: rateSheet ? [rateSheet] : [],
      };
      const load = await addDoc(collection(db, "loads"), data);
      packages.map((pac: any, index: number) => {
        dispatch(addPackage({ ...pac, loaduid: load.id, index }))
      })
      stops.map((stop: any, index: number) => {
        dispatch(addStop({ ...stop, loaduid: load.id, index }))
      })
      dispatch(addLoadSuccess({ ...data, id: load.id }));
      return { uid: load.id, status: "success" }
    }
    catch (err) {
      console.log("error ===>", err)
      dispatch(hasError(err));
      return { uid: undefined, status: "error" }
    }
  };
}

export function addPackage(data: any) {
  return async () => {
    try {
      await setDoc(doc(db, "packages", data.uid), { ...data })
    }
    catch (err) {
      dispatch(hasError(err));
    }
  };
}
export function removePackage(data: any) {
  return async () => {
    try {
      await deleteDoc(doc(db, "packages", data))
    }
    catch (err) {
      dispatch(hasError(err));
    }
  };
}
export async function getPackages(loadId: string) {
  return await getDocs(query(collection(getFirestore(), "packages"), where("loaduid", "==", loadId))).then((response) => {
    let docs = response.docs.map((load) => {
      let data = load.data();
      return data
    })
    docs.sort((a: any, b: any) =>
      a.index - b.index
    );
    return docs;
  });
}



export function updateLoad(values: any, packages: any, stops: any, removedStopIds: any, removedPackageIds: any) {
  return async () => {
    try {
      dispatch(startLoading());
      const data = {
        customerId: values.customerId,
        contactRef: values.contactRef,
        customerLoad: values.customerLoad,
        carrierId: values.carrierId,
        status: values.status,
        rateDetails: {
          rate: values.rateDetails.rate,
          type: values.rateDetails.type,
        },
        salesPersonId: values.salesPersonId,
        freightDetails: {
          freightType: values.freightDetails.freightType,
          commodity: values.freightDetails.commodity,
          temperature: values.freightDetails.temperature,
          temperatureType: values.freightDetails.temperatureType,
          cycle: values.freightDetails.cycle,
          pulp: values.freightDetails.pulp,
        },
        requirements: {
          trailerType: values.requirements.trailerType,
          trailerSize: values.requirements.trailerSize,
          team: values.requirements.team,
        },
        updatedAt: new Date().getTime(),
      };
      console.log("data ===>", data)
      await updateDoc(doc(db, "loads", values.id), data);
      await getLoadList();
      packages.map((pac: any, index: number) => {
        dispatch(addPackage({ ...pac, loaduid: values.id, index }))
      })
      stops.map((stop: any, index: number) => {
        dispatch(addStop({ ...stop, loaduid: values.id, index }))
      })
      removedStopIds.map((data: any,) => {
        dispatch(removeStops(data))
      })
      removedPackageIds.map((data: any,) => {
        dispatch(removePackage(data))
      })
      dispatch(addLoadSuccess({ ...values }));
      return { uid: values.id, status: "success" }
    } catch (err) {
      console.log("update load error ===>", err)
      return { uid: err, status: "error" }

    }
  };
}
export async function findLoadById(id: string) {
  const findLoadDetails = await getDoc(doc(db, "loads", id));
  if (findLoadDetails.exists()) {
    return findLoadDetails.data();
  }
}

export function removeLoad(id: string) {
  return async () => {
    try {
      await deleteDoc(doc(db, "loads", id))
      return { status: "success" }
    } catch (error) {
      dispatch(hasError(error));
      return { status: "error" }
    }
  };
}
