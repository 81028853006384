import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
// Types
import {
    Friend,
    Gallery,
    Profile,
    UserPost,
    Follower,
    UserData,
    CreditCard,
    UserInvoice,
    UserManager,
    UserAddressBook,
    NotificationSettings,
} from "../../@types/user";
import {
    CarrierManager
} from "../../@types/carrier";
// firestore
import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import firebase, { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDoc,
    addDoc,
    query,
    orderBy,
    limit,
    setDoc,
    updateDoc,
    doc,
    serverTimestamp,
    getDocs,
    onSnapshot,
    Unsubscribe,
} from "firebase/firestore";
// configuration
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
// ----------------------------------------------------------------------
// interface
type CarrierState = {
    isLoading: boolean;
    error: boolean;
    carrierList: CarrierManager[];
    displayCarrierContactForm: boolean;
};
// initial state
const initialState: CarrierState = {
    isLoading: false,
    error: false,
    carrierList: [],
    displayCarrierContactForm: false,
};

const slice = createSlice({
    name: "carrier",
    initialState,
    reducers: {
        // Change State For Display Contact Form
        carrierDisplayContactForm(state, action) {
            state.displayCarrierContactForm = action.payload;
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE CARRIER
        deleteCarrier(state, action) {
            const deleteCarrier = filter(
                state.carrierList,
                (carrier) => carrier.id !== action.payload
            );
            state.carrierList = deleteCarrier;
        },

        // GET MANAGE CARRIER
        getCarrierListSuccess(state, action) {
            state.isLoading = false;
            state.carrierList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteCarrier, carrierDisplayContactForm } = slice.actions;

// ------------Get Carrier record for specific ID--------------------------------
export async function findCarrierById(id: string) {
    const findCarrierDetails = await getDoc(doc(db, 'carriers', id))
    if (findCarrierDetails.exists()) {
        return findCarrierDetails.data();
    }
};
// --------------------------Get Carrier List--------------------------------------------
export function getCarrierList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const carriers = await getDocs(collection(getFirestore(), "carriers"));
            dispatch(
                slice.actions.getCarrierListSuccess(
                    carriers.docs.map((carrier: any) => ({ ...carrier.data(), id: carrier.id }))
                )
            );

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
// ----------------------------------------------------------------------
