import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
// Types
import {
    Friend,
    Gallery,
    Profile,
    UserPost,
    Follower,
    UserData,
    CreditCard,
    UserInvoice,
    UserManager,
    UserAddressBook,
    NotificationSettings,
} from "../../@types/user";
import {
    businessTypesManager
} from "../../@types/businessTypes";
// firestore
import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import firebase, { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDoc,
    addDoc,
    query,
    orderBy,
    limit,
    setDoc,
    updateDoc,
    doc,
    serverTimestamp,
    getDocs,
    onSnapshot,
    Unsubscribe,
} from "firebase/firestore";
// configuration
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
// ----------------------------------------------------------------------
// interface
type BusinessTypesState = {
    isLoading: boolean;
    error: boolean;
    businessTypesList: businessTypesManager[];
};
// initial state
const initialState: BusinessTypesState = {
    isLoading: false,
    error: false,
    businessTypesList: [],
};

const slice = createSlice({
    name: "businessTypes",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET MANAGE BUSINESS TYPES
        getBusinessTypesListSuccess(state, action) {
            state.isLoading = false;
            state.businessTypesList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
// --------------------------Get Carrier List--------------------------------------------
export function getBusinessTypes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const businessTypes = await getDocs(collection(getFirestore(), "businesstypes"));
            dispatch(
                slice.actions.getBusinessTypesListSuccess(
                    businessTypes.docs.map((businessType: any) => ({ ...businessType.data(), id: businessType.id }))
                )
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
// ----------------------------------------------------------------------