import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import blogReducer from "./slices/blog";
import userReducer from "./slices/user";
import brokerReducer from "./slices/broker";
import carrierReducer from "./slices/carrier";
import businessTypesReducer from "./slices/businessTypes";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import driverReducer from "./slices/driver";
import facilityReducer from "./slices/facility"
import truckReducer from "./slices/truck"
import trailerReducer from "./slices/trailer"
import loadReducer from "./slices/load"
import tripReducer from "./slices/trip"
import stopReducer from "./slices/stop"
import dispatchReducer from "./slices/dispatches"

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: []
}

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"]
}

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["userList"]
}
const brokerPersistConfig = {
  key: "broker",
  storage,
  keyPrefix: "redux-",
  whitelist: ["brokerList"]
}
const carrierPersistConfig = {
  key: "carrier",
  storage,
  keyPrefix: "redux-",
  whitelist: ["carrierList"]
}
const businessTypesPersistConfig = {
  key: "businessTypes",
  storage,
  keyPrefix: "redux-",
  whitelist: ["businessTypesList"]
}
const driverPersistConfig = {
  key: "driver",
  storage,
  keyPrefix: "redux-",
  whitelist: ["driverList"]
}
const facilityPersistConfig = {
  key: "facility",
  storage,
  keyPrefix: "redux-",
  whitelist: ["facilityList"]
}
const truckPersistConfig = {
  key: "truck",
  storage,
  keyPrefix: "redux-",
  whitelist: ["truckList"]
}
const trailerPersistConfig = {
  key: "trailer",
  storage,
  keyPrefix: "redux-",
  whitelist: ["trailerList"]
}
const loadPersistConfig = {
  key: "load",
  storage,
  keyPrefix: "redux-",
  whitelist: ["loadList"]
}
const tripPersistConfig = {
  key: "trip",
  storage,
  keyPrefix: "redux-",
  whitelist: ["tripList"]
}
const stopPersistConfig = {
  key: "stop",
  storage,
  keyPrefix: "redux-",
  whitelist: ["stopList"]
}
const DispatchPersistConfig = {
  key: "disatches",
  storage,
  keyPrefix: "redux-",
  whitelist: ["dispatchList"]
}
const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: persistReducer(userPersistConfig, userReducer),
  broker: persistReducer(brokerPersistConfig, brokerReducer),
  carrier: persistReducer(carrierPersistConfig, carrierReducer),
  driver: persistReducer(driverPersistConfig, driverReducer),
  facility: persistReducer(facilityPersistConfig, facilityReducer),
  truck: persistReducer(truckPersistConfig, truckReducer),
  trailer: persistReducer(trailerPersistConfig, trailerReducer),
  load: persistReducer(loadPersistConfig, loadReducer),
  trip: persistReducer(tripPersistConfig, tripReducer),
  stop: persistReducer(stopPersistConfig, stopReducer),
  dispatches: persistReducer(DispatchPersistConfig, dispatchReducer),
  businessTypes: persistReducer(
    businessTypesPersistConfig,
    businessTypesReducer
  ),
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer)
})

export { rootPersistConfig, rootReducer };
