import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";
import {
    Friend,
    Gallery,
    Profile,
    UserPost,
    Follower,
    UserData,
    CreditCard,
    UserInvoice,
    UserManager,
    UserAddressBook,
    NotificationSettings,
} from "../../@types/user";
import {
    BrokerManager
} from "../../@types/broker";

import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";
import firebase, { initializeApp } from "firebase/app";
import {
    getFirestore,
    collection,
    getDoc,
    addDoc,
    query,
    orderBy,
    limit,
    setDoc,
    updateDoc,
    doc,
    serverTimestamp,
    getDocs,
    onSnapshot,
    Unsubscribe,
} from "firebase/firestore";
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
// ----------------------------------------------------------------------

type BrokerState = {
    isLoading: boolean;
    error: boolean;
    brokerList: BrokerManager[];
    displayBrokerContactForm: boolean;
};

const initialState: BrokerState = {
    isLoading: false,
    error: false,
    brokerList: [],
    displayBrokerContactForm: false,
};

const slice = createSlice({
    name: "broker",
    initialState,
    reducers: {
        // Change State For Display Contact Form
        brokerDisplayContactForm(state, action) {
            state.displayBrokerContactForm = action.payload;
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE BROKER
        deleteBroker(state, action) {
            const deleteBroker = filter(
                state.brokerList,
                (broker) => broker.id !== action.payload
            );
            state.brokerList = deleteBroker;
        },
        // GET MANAGE BROKERS
        getBrokerListSuccess(state, action) {
            state.isLoading = false;
            state.brokerList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteBroker, brokerDisplayContactForm } = slice.actions;

// ----------------------------------------------------------------------
export function getBrokerList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const brokers = await getDocs(collection(getFirestore(), "brokers"));
            dispatch(
                slice.actions.getBrokerListSuccess(
                    brokers.docs.map((broker: any) => ({ ...broker.data(), id: broker.id }))
                )
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export async function findBrokerById(id: string) {
    const findBrokerDetails = await getDoc(doc(db, 'brokers', id))
    if (findBrokerDetails.exists()) {
        return findBrokerDetails.data();
    }
};
// ----------------------------------------------------------------------
