import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { DriverManager } from "../../@types/driver";
import { getFirestore, collection, getDocs, getDoc, doc, } from "firebase/firestore";
import { findCarrierById } from "./carrier";
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
const app = initializeApp(fbConfig)
const db = getFirestore(app)
// ----------------------------------------------------------------------

type DriverState = {
    isLoading: boolean;
    error: boolean;
    driverList: DriverManager[];
};

const initialState: DriverState = {
    isLoading: false,
    error: false,
    driverList: [],
};

const slice = createSlice({
    name: "driver",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // DELETE DRIVER
        deleteDriver(state, action) {
            const deleteDriver = filter(
                state.driverList,
                (driver) => driver.id !== action.payload
            );
            state.driverList = deleteDriver;
        },
        // GET MANAGE DRIVER
        getDriverListSuccess(state, action) {
            state.isLoading = false;
            state.driverList = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteDriver } = slice.actions;

// ------------------------Get Driver List--------------------------------
export function getDriverList() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const drivers = await getDocs(collection(getFirestore(), "drivers"));
            let findDocs = drivers.docs.map((driver) => {
                let data = driver.data();
                data.id = driver.id;
                return data
            });
            const driverDocs = await Promise.all(
                findDocs.map(
                    (state) => findCarrierById(state.carrierId).then(carrierDetails => ({ ...state, carrierDetails }))
                )
            );
            dispatch(
                slice.actions.getDriverListSuccess(driverDocs)
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};
export async function findDriverById(id: string) {
    const findDriverDetails = await getDoc(doc(db, 'drivers', id))
    if (findDriverDetails.exists()) {
        return findDriverDetails.data();
    }
};
 
