import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import { TripManager } from "../../@types/trip";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  addDoc,
  setDoc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";
import { findCarrierById } from "./carrier";
// ----------------------------------------------------------------------
import { initializeApp } from "firebase/app";
import { fbConfig } from "config";
import { updateDispatchebyId } from "./dispatches";
import { updateStopsbyId } from "./stop";
const app = initializeApp(fbConfig);
const db = getFirestore(app);
type TripState = {
  isLoading: boolean;
  error: boolean;
  tripList: TripManager[];
};

const initialState: TripState = {
  isLoading: false,
  error: false,
  tripList: [],
};

const slice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // ADD LOAD
    addTripSuccess(state, action) {
      state.isLoading = false;
      state.tripList = [...state.tripList, action.payload];
    },
    // DELETE LOAD
    deleteTrip(state, action) {
      const deleteTrip = filter(
        state.tripList,
        (trip) => trip.id !== action.payload
      );
      state.tripList = deleteTrip;
    },
    // GET MANAGE LOAD
    getTripListSuccess(state, action) {
      state.isLoading = false;
      state.tripList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTrip } = slice.actions;

// ------------------------Get Trip List--------------------------------
export function getTripList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const trips = await getDocs(collection(getFirestore(), "trips"));
      let findDocs = trips.docs.map((trip) => {
        let data = trip.data();
        data.id = trip.id;
        return data;
      });
      dispatch(slice.actions.getTripListSuccess(findDocs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addTrip(values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const data = {
      status: "Not started Yet",
      tripId: "T" + JSON.stringify(Math.floor(Math.random() * 90000) + 10000),
      dispatchIds: values.dispatchIds,
      createdAt: Date.now(),
      carrierId: values.carrierId,
      truckId: values.truckId,
      trailerId: values.trailerId,
      driverId: values.driverId,
      tripData: values.tripData,
      loadId: values.loadId
    };
    const trip = await addDoc(collection(db, "trips"), data);
    let dispatchValuesUpdate = {
      carrierId: values.carrierId,
      truckId: values.truckId,
      trailerId: values.trailerId,
      driverId: values.driverId,
      tripId: data.tripId
    }
    dispatch(slice.actions.addTripSuccess({ ...data, id: trip.id }));
    values.dispatchIds.map(async (dispatchId: string) => {
      await updateDoc(doc(db, "dispatches", dispatchId), dispatchValuesUpdate);
    })
  };
}
export function updateTrip(values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    await updateDoc(doc(db, "trips", values.id), values);
    let dispatchValuesUpdate = {
      carrierId: values.carrierId,
      truckId: values.truckId,
      trailerId: values.trailerId,
      driverId: values.driverId,
      tripId: values.tripId
    }
    values.dispatchIds.map(async (dispatchId: string) => {
      await updateDoc(doc(db, "dispatches", dispatchId), dispatchValuesUpdate);
    })
    await getTripList();
  };
}

export async function findTripById(id: string) {
  const findTripDetails = await getDoc(doc(db, "trips", id));
  if (findTripDetails.exists()) {
    let data = findTripDetails.data();
    data.id = findTripDetails.id
    return data
  }
}

export async function findTripByTripId(id: string) {
  return await getDocs(query(collection(getFirestore(), "trips"), where("tripId", "==", id))).then((response) => {
    let data = response.docs[0].data();
    data.id = response.docs[0].id;
    return data
  })
}


export function removeTrip(id: string, tripId?: string) {
  return async () => {
    try {
      await getDocs(query(collection(getFirestore(), "dispatches"), where("tripId", "==", tripId ? tripId : id))).then((response) => {
        response.docs.map((dispatchData) => {
          dispatch(updateDispatchebyId(dispatchData.id, {
            carrierId: "",
            truckId: "",
            trailerId: "",
            driverId: "",
            tripId: ""
          })
          )
        })
      })
      await getDocs(query(collection(getFirestore(), "stops"), where("tripId", "==", tripId ? tripId : id))).then((response) => {
        response.docs.map((stopData) => {
          dispatch(updateStopsbyId(stopData.id, {
            tripId: ""
          })
          )
        })
      })
      return await deleteDoc(doc(db, "trips", id))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getTripsByLoadId(id: string) {
  return await getDocs(query(collection(getFirestore(), "trips"), where("loadId", "==", id))).then((response) => {
    return response.docs.map((trip) => {
      let data = trip.data();
      data.uid = trip.id;
      return data;
    });
  })
}